import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './Navigation';
import PartnersPage from './PartnersPage';
import ProductsPage from './ProductsPage';
import WarehousesPage from './WarehousesPage';
import InventoryPage from './InventoryPage';
import SalesPage from './SalesPage';
import OrdersPage from './OrdersPage';
import LandingPage from './LandingPage';
import PurchaseOrdersPage from './PurchaseOrdersPage';
import TestTypeaheadPage from './TestTypeaheadPage'; // Import the TestTypeaheadPage
import axiosInstance from '../axiosInstance';

const MainPage = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showLoginModal, setShowLoginModal] = useState(false);
  
    const handleShowLoginModal = () => setShowLoginModal(true);
    const handleCloseLoginModal = () => setShowLoginModal(false);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post('/api/users/login', {
                username,
                password
            });

            if (response.data) {
                setLoggedIn(true);
                setUsername(username); // Assume the username from the login form is what you want to display
                localStorage.setItem('jwtToken', response.data.token); // Securely store the token
                handleCloseLoginModal();
                alert(`Login Successs: ${response.data.message}`);
            }
        } catch (error) {
            alert(`Login Failed: ${error.response ? error.response.data.message : 'Server error'}`);
        }
    };

    const handleLogoff = () => {
        localStorage.removeItem('jwtToken');
        setLoggedIn(false);
        setUsername('');
        setPassword('');
    };

    return (
        <Router>
          <Navigation 
            loggedIn={loggedIn} 
            username={username} 
            showLoginModal={showLoginModal} 
            handleShowLoginModal={handleShowLoginModal} 
            handleCloseLoginModal={handleCloseLoginModal} 
            handleLogin={handleLogin} 
            setUsername={setUsername} 
            setPassword={setPassword} 
            handleLogoff={handleLogoff} 
          />
          <Routes>
            {loggedIn ? (
              <>
                <Route path="/partners" element={<PartnersPage />} />
                <Route path="/products" element={<ProductsPage />} />
                <Route path="/warehouses" element={<WarehousesPage />} />
                <Route path="/inventory" element={<InventoryPage />} />
                <Route path="/sales" element={<SalesPage />} />
                <Route path="/orders" element={<OrdersPage />} />
                <Route path="/purchaseOrders" element={<PurchaseOrdersPage />} />
                <Route path="/test-typeahead" element={<TestTypeaheadPage />} /> {/* Add this route */}
              </>
            ) : (
                // Default to LandingPage when not logged in
                <Route path="/" element={<LandingPage handleShowLoginModal={handleShowLoginModal} />} />
            )}
          </Routes>
        </Router>
    );
};

export default MainPage;
