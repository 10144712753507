import React, { useEffect } from 'react';
import $ from 'jquery';
import Bloodhound from 'typeahead.js/dist/bloodhound'; // Import Bloodhound from typeahead.js
import 'typeahead.js/dist/typeahead.bundle.min.js';

const TestTypeaheadPage = () => {

    useEffect(() => {
        // Function to initialize the Typeahead for products
        const initializeTypeahead = (selector, data) => {
            var bloodhoundEngine = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.whitespace,
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                local: data
            });

            $(selector).typeahead({
                hint: true,
                highlight: true,
                minLength: 1
            },
            {
                name: 'data',
                source: bloodhoundEngine
            });
        };

        // Fetching products and initializing Typeahead
        const fetchProducts = async () => {
            const token = localStorage.getItem('jwtToken');
            try {
                const response = await fetch('https://io.pbw.net/api/products', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json'
                    }
                });
                const json = await response.json();
                const productNames = json.data.map(product => product.productName); // Extract product names
                initializeTypeahead('.product-typeahead', productNames); // Initialize Typeahead for products
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        // Fetching partners and initializing Typeahead
        const fetchPartners = async () => {
            const token = localStorage.getItem('jwtToken');
            try {
                const response = await fetch('https://io.pbw.net/api/partners', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json'
                    }
                });
                const json = await response.json();
                const partnerNames = json.data.map(partner => partner.partnerName); // Extract partner names
                initializeTypeahead('.partner-typeahead', partnerNames); // Initialize Typeahead for partners
            } catch (error) {
                console.error('Error fetching partners:', error);
            }
        };

        // Fetch products and partners on mount
        fetchProducts();
        fetchPartners();
    }, []);

    return (
        <div className="container mt-5">
            <h2>Search Products</h2>
            <input type="text" className="product-typeahead tt-query form-control" placeholder="Search Products..." autoComplete="off" spellCheck="false" />

            <h2>Search Partners</h2>
            <input type="text" className="partner-typeahead tt-query form-control" placeholder="Search Partners..." autoComplete="off" spellCheck="false" />
        </div>
    );
};

export default TestTypeaheadPage;
