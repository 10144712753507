// src/axiosInstance.js
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://io.pbw.net',
  headers: {
    'Content-Type': 'application/json',
    'X-Secret-String': process.env.REACT_APP_SECRET_STRING,
  },
});

export default axiosInstance; // Export the instance