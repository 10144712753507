import React from 'react';
import { Button, Container } from 'react-bootstrap';

const LandingPage = ({handleShowLoginModal}) => {
  return (
    <Container className="mt-5">
      <h1>Welcome to PBW.net!</h1>
      <p>This is your starting point for managing sales and inventory.</p>
      <Button onClick={handleShowLoginModal} variant="primary">Log In</Button>
    </Container>
  );
};

export default LandingPage; // Export the component
