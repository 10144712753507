import React from 'react';
import { Navbar, Container, Nav, Button, Modal, Form, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Navigation = ({ loggedIn, username, showLoginModal, handleShowLoginModal, handleCloseLoginModal, handleLogin, setUsername, setPassword, handleLogoff }) => {
  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
        <Container>
          <Navbar.Brand as={Link} to="/">PBW.net</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {loggedIn && (
                <>
                  <Nav.Link as={Link} to="/partners">Partners</Nav.Link>
                  <Nav.Link as={Link} to="/products">Products</Nav.Link>
                  <Nav.Link as={Link} to="/warehouses">Warehouses</Nav.Link>
                  <Nav.Link as={Link} to="/inventory">Inventory</Nav.Link>
                  <Nav.Link as={Link} to="/purchaseOrders">POs</Nav.Link>
                  <Nav.Link as={Link} to="/sales">Sales</Nav.Link>
                  <Nav.Link as={Link} to="/orders">Orders</Nav.Link>
                  <Nav.Link as={Link} to="/test-typeahead">Test Typeahead</Nav.Link> {/* New Link */}
                  <Dropdown align="end">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {username}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handleLogoff}>Logoff</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )}
              {!loggedIn && (
                <Button variant="outline-success" onClick={handleShowLoginModal}>Login</Button>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal show={showLoginModal} onHide={handleCloseLoginModal}>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleLogin}>
            <Form.Group className="mb-3" controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control type="text" placeholder="Enter username" onChange={(e) => setUsername(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
            </Form.Group>
            <Button variant="primary" type="submit">Submit</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Navigation;
