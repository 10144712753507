import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Form, Pagination } from 'react-bootstrap';

const ProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchProducts = async (page = 1, limit = 10) => {
    const token = localStorage.getItem('jwtToken');
    try {
      const response = await axios.get('https://io.pbw.net/api/products', {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json' // Expecting JSON response
        },
        params: { page, limit } // Adding pagination parameters
      });
      console.log('Raw Response:', response.data); // Log the entire response object

      const { data, pageInfo } = response.data;
      console.log('Fetched Products:', data); // Log the data portion
      console.log('Page Info:', pageInfo); // Log the pagination info

      setProducts(data);
      setCurrentPage(pageInfo.currentPage);
      setTotalPages(pageInfo.totalPages);
    } catch (error) {
      console.error('Error fetching products:', error.toString());
    }
  };

  useEffect(() => {
    fetchProducts(); // Fetch the first page with the default limit on component mount
  }, []);

  const handleShowModal = (product = {}) => {
    setCurrentProduct(product);
    setIsUpdate(Object.keys(product).length !== 0);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentProduct({});
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem('jwtToken');
    const productData = new FormData(event.target);
    const data = Object.fromEntries(productData.entries());
    data.serialized = data.serialized === "true"; // Convert serialized to boolean

    // Log the raw form data
    console.log('Raw form data:', data);

    try {
      const headers = {
        'Authorization': `Bearer ${token}`,
      };

      if (isUpdate) {
        const productId = currentProduct.productID; // Assuming you have the ID of the product to update
        console.log('Updating product with ID:', productId, 'and data:', data);
        await axios.put(`https://io.pbw.net/api/products/${productId}`, data, { headers });
      } else {
        console.log('Adding new product data:', data);
        await axios.post('https://io.pbw.net/api/products/', data, { headers });
      }

      fetchProducts(currentPage); // Refetch the products after submit
      handleCloseModal();

    } catch (error) {
      console.error('Error saving the product:', error.toString());
    }
  };

  const handleDelete = async (productId) => {
    const token = localStorage.getItem('jwtToken');
    try {
      await axios.delete(`https://io.pbw.net/api/products/${productId}`, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json' // Expecting JSON response
        }
      });
      fetchProducts(currentPage); // Refetch the products after delete
    } catch (error) {
      console.error('Error deleting the product:', error.toString());
    }
  };

  const handlePageChange = (pageNumber) => {
    fetchProducts(pageNumber);
  };

  return (
    <div>
      <h1>Products</h1>
      <Button onClick={() => handleShowModal()}>Add Product</Button>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Product ID</th>
            <th>Product Name</th>
            <th>Description</th>
            <th>Price</th>
            <th>Serialized</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {console.log('Products in state:', products)}
          {products && products.length > 0 ? (
            products.map(product => (
              <tr key={product.productID}>
                <td>{product.productID}</td>
                <td>{product.productName}</td>
                <td>{product.productDescription}</td>
                <td>{product.price}</td>
                <td>{product.serialized ? "Yes" : "No"}</td>
                <td>
                  <Button variant="info" size="sm" onClick={() => handleShowModal(product)}>Edit</Button>{' '}
                  <Button variant="danger" size="sm" onClick={() => handleDelete(product.productID)}>Delete</Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">No products found.</td>
            </tr>
          )}
        </tbody>
      </Table>

      <Pagination>
        <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
        {[...Array(totalPages)].map((_, index) => (
          <Pagination.Item
            key={index + 1}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
        <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
      </Pagination>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{isUpdate ? 'Update Product' : 'Add Product'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Product Name</Form.Label>
              <Form.Control name="productName" defaultValue={currentProduct.productName || ''} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control name="productDescription" defaultValue={currentProduct.productDescription || ''} as="textarea" rows={3} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Price</Form.Label>
              <Form.Control name="price" type="number" step="0.01" defaultValue={currentProduct.price || ''} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check 
                type="checkbox" 
                name="serialized" 
                label="Serialized" 
                defaultChecked={currentProduct.serialized || false} 
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProductsPage;
