import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Form } from 'react-bootstrap';

const WarehousesPage = () => {
  const [warehouses, setWarehouses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentWarehouse, setCurrentWarehouse] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);

  const fetchWarehouses = async (page = 1, limit = 10) => {
    const token = localStorage.getItem('jwtToken');
    try {
      const response = await axios.get('https://io.pbw.net/api/warehouses', {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json' // Expecting JSON response
        },
        params: { page, limit } // Adding pagination parameters
      });
      console.log('Raw Response:', response.data); // Log the entire response object

      const { data, pageInfo } = response.data;
      console.log('Fetched Warehouses:', data); // Log the data portion
      console.log('Page Info:', pageInfo); // Log the pagination info

      setWarehouses(data);
    } catch (error) {
      console.error('Error fetching warehouses:', error.toString());
    }
  };

  useEffect(() => {
    fetchWarehouses(); // Fetch the first page with the default limit on component mount
  }, []);

  const handleShowModal = (warehouse = {}) => {
    setCurrentWarehouse(warehouse);
    setIsUpdate(Object.keys(warehouse).length !== 0);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentWarehouse({});
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem('jwtToken');
    const warehouseData = new FormData(event.target);
    const data = Object.fromEntries(warehouseData.entries());

    // Log the raw form data
    console.log('Raw form data:', data);

    try {
      const headers = {
        'Authorization': `Bearer ${token}`,
      };

      if (isUpdate) {
        const warehouseId = currentWarehouse.warehouseID; // Assuming you have the ID of the warehouse to update
        console.log('Updating warehouse with ID:', currentWarehouse.warehouseID, 'and data:', data);
        await axios.put(`https://io.pbw.net/api/warehouses/${warehouseId}`, data, { headers });
      } else {
        console.log('Adding new warehouse data:', data);
        await axios.post('https://io.pbw.net/api/warehouses/', data, { headers });
      }

      fetchWarehouses(); // Refetch the warehouses after submit
      handleCloseModal();

    } catch (error) {
      console.error('Error saving the warehouse:', error.toString());
    }
  };

  const handleDelete = async (warehouseId) => {
    const token = localStorage.getItem('jwtToken');
    try {
      await axios.delete(`https://io.pbw.net/api/warehouses/${warehouseId}`, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json' // Expecting JSON response
        }
      });
      fetchWarehouses(); // Refetch the warehouses after delete
    } catch (error) {
      console.error('Error deleting the warehouse:', error.toString());
    }
  };

  return (
    <div>
      <h1>Warehouses</h1>
      <Button onClick={() => handleShowModal()}>Add Warehouse</Button>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Warehouse ID</th>
            <th>Warehouse Name</th>
            <th>Location</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {warehouses && warehouses.length > 0 ? (
            warehouses.map(warehouse => (
              <tr key={warehouse.warehouseID}>
                <td>{warehouse.warehouseID}</td>
                <td>{warehouse.name}</td>
                <td>{warehouse.location}</td>
                <td>{warehouse.created_at}</td>
                <td>
                  <Button variant="info" size="sm" onClick={() => handleShowModal(warehouse)}>Edit</Button>{' '}
                  <Button variant="danger" size="sm" onClick={() => handleDelete(warehouse.warehouseID)}>Delete</Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No warehouses found.</td>
            </tr>
          )}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{isUpdate ? 'Update Warehouse' : 'Add Warehouse'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Warehouse Name</Form.Label>
              <Form.Control name="name" defaultValue={currentWarehouse.name || ''} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Location</Form.Label>
              <Form.Control name="location" defaultValue={currentWarehouse.location || ''} as="textarea" rows={3} />
            </Form.Group>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default WarehousesPage;
