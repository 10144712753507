import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Modal, Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const PurchaseOrderPage = () => {
  const [partners, setPartners] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentOrder, setCurrentOrder] = useState({});

  // Fetch partners from the API
  const fetchPartners = async () => {
    const token = localStorage.getItem('jwtToken');
    try {
      const response = await axios.get('https://io.pbw.net/api/partners', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setPartners(response.data); // Assuming response.data is an array of partners
    } catch (error) {
      console.error('Error fetching partners:', error.toString());
    }
  };

  useEffect(() => {
    fetchPartners();
  }, []);

  // Handle modal visibility
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentOrder({});
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem('jwtToken');
    try {
      // Logic for submitting the form
      console.log('Submitting order:', currentOrder);
      // Add API call here to save the order
      handleCloseModal();
    } catch (error) {
      console.error('Error saving the purchase order:', error.toString());
    }
  };

  return (
    <div>
      <h1>Purchase Orders</h1>
      <Button onClick={handleShowModal}>Add Purchase Order</Button>

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Purchase Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Partner</Form.Label>
              <Typeahead
                id="partner-typeahead"
                labelKey="partnerName"
                onChange={(selected) => {
                  const partnerID = selected.length > 0 ? selected[0].partnerID : '';
                  setCurrentOrder({ ...currentOrder, partnerID });
                }}
                options={partners} // Use the fetched partners data
                placeholder="Choose a partner..."
                selected={partners.filter(p => p.partnerID === currentOrder.partnerID)}
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Save Purchase Order
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PurchaseOrderPage;
