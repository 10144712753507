import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Form } from 'react-bootstrap';

const PartnersPage = () => {
  const [partners, setPartners] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentPartner, setCurrentPartner] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);

  const fetchPartners = async (page = 1, limit = 10) => {
    const token = localStorage.getItem('jwtToken');
    try {
      const response = await axios.get('https://io.pbw.net/api/partners', {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json' // Expecting JSON response
        },
        params: { page, limit } // Adding pagination parameters
      });
      console.log('Raw Response:', response.data); // Log the entire response object

      const { data, pageInfo } = response.data;
      console.log('Fetched Partners:', data); // Log the data portion
      console.log('Page Info:', pageInfo); // Log the pagination info

      setPartners(data);
    } catch (error) {
      console.error('Error fetching partners:', error.toString());
    }
  };

  useEffect(() => {
    fetchPartners(); // Fetch the first page with the default limit on component mount
  }, []);

  const handleShowModal = (partner = {}) => {
    setCurrentPartner(partner);
    setIsUpdate(Object.keys(partner).length !== 0);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentPartner({});
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem('jwtToken');
    const partnerData = new FormData(event.target);
    const data = Object.fromEntries(partnerData.entries());

    // Log the raw form data
    console.log('Raw form data:', data);

    try {
      const headers = {
        'Authorization': `Bearer ${token}`,
      };

      if (isUpdate) {
        const partnerId = currentPartner.partnerID; // Assuming you have the ID of the partner to update
        console.log('Updating partner with ID:', partnerId, 'and data:', data);
        await axios.put(`https://io.pbw.net/api/partners/${partnerId}`, data, { headers });
      } else {
        console.log('Adding new partner data:', data);
        await axios.post('https://io.pbw.net/api/partners/', data, { headers });
      }

      fetchPartners(); // Refetch the partners after submit
      handleCloseModal();

    } catch (error) {
      console.error('Error saving the partner:', error.toString());
    }
  };

  const handleDelete = async (partnerId) => {
    const token = localStorage.getItem('jwtToken');
    try {
      await axios.delete(`https://io.pbw.net/api/partners/${partnerId}`, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json' // Expecting JSON response
        }
      });
      fetchPartners(); // Refetch the partners after delete
    } catch (error) {
      console.error('Error deleting the partner:', error.toString());
    }
  };

  return (
    <div>
      <h1>Partners</h1>
      <Button onClick={() => handleShowModal()}>Add Partner</Button>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Partner ID</th>
            <th>Partner Name</th>
            <th>Partner Type</th>
            <th>Address</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {partners && partners.length > 0 ? (
            partners.map(partner => (
              <tr key={partner.partnerID}>
                <td>{partner.partnerID}</td>
                <td>{partner.partnerName}</td>
                <td>{partner.partnerType}</td>
                <td>{partner.address}</td>
                <td>{partner.email}</td>
                <td>{partner.phone}</td>
                <td>
                  <Button variant="info" size="sm" onClick={() => handleShowModal(partner)}>Edit</Button>{' '}
                  <Button variant="danger" size="sm" onClick={() => handleDelete(partner.partnerID)}>Delete</Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">No partners found.</td>
            </tr>
          )}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{isUpdate ? 'Update Partner' : 'Add Partner'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Partner Name</Form.Label>
              <Form.Control name="partnerName" defaultValue={currentPartner.partnerName || ''} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Partner Type</Form.Label>
              <Form.Control name="partnerType" defaultValue={currentPartner.partnerType || ''} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Address</Form.Label>
              <Form.Control name="address" defaultValue={currentPartner.address || ''} as="textarea" rows={3} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control name="email" defaultValue={currentPartner.email || ''} type="email" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Phone</Form.Label>
              <Form.Control name="phone" defaultValue={currentPartner.phone || ''} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Additional Info</Form.Label>
              <Form.Control name="additionalInfo" defaultValue={currentPartner.additionalInfo || ''} as="textarea" rows={3} />
            </Form.Group>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PartnersPage;
